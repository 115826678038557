.footing {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 3.75rem 0.19rem 4.56rem;
  border-radius: 0.625rem;
  background: #fff;
  margin-bottom: 9.06rem;
  p {
    color: #232323;
    font-family: Jost;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
      color: var(--olive-green);
      width: 1.23419rem;
      height: 1.4375rem;
    }
  }
  .security {
    margin-right: 10.19rem;
  }
  .line-v {
    width: 0.09375rem;
    height: 4.9375rem;
    background: #c2dc83;
    margin-right: 9rem;
  }
  .certify {
    margin-right: 11.25rem;
  }
}
@media (max-width: 768px), (max-width: 1024px) {
  .footing {
    padding: 1.69rem 1.5rem 2rem 2.06rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .security {
      margin-right: 0;
    }
    .line-v {
      margin-top: 1.06rem;
      width: 100%;
      @include line;
      background: #c2dc83;
      margin-right: 0;
    }
    .certify {
      margin-top: 1.44rem;
      margin-right: 0;
    }
    .delivery {
      margin-top: 1.31rem;
    }
  }
}
