.event {
  padding: 2.8125rem 0.875rem 2.8125rem 0.875rem;
  background-color: #fff;

  h3 {
    color: #170b0b;
    text-align: center;
    font-size: 2.875rem; // 46px
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01725rem; // -0.276px
    text-transform: uppercase;
    margin-bottom: 2.1875rem; // 35px
  }

  &-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 44px;
    > div {
      width: 85%;
    }
    > div > div {
      display: flex;
      align-items: center;
      gap: 1.4375rem; // 23px
      color: var(--olive-green);
      font-size: 27px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.162px;
      text-transform: uppercase;
      img {
        width: 34px;
        height: 34px;
      }
    }
  }
  &-desc {
    margin-bottom: 29px;

    p {
      color: #313131;
      text-align: center;
      font-family: Roboto;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 160% */
      letter-spacing: -0.15px;
      text-transform: capitalize;
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      padding: 0.625rem 4.875rem;
      background-color: var(--olive-green);
      border-radius: 30px;
      color: var(--white, #fff);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      border: none;
      font-weight: 600;
      line-height: 36px; /* 120% */
    }
  }
}

@media (min-width: 768px) {
  .event {
    &-infos {
      > div {
        display: flex;
        width: 100%;
        gap: 71px;
        justify-content: center;
      }
    }
  }
}

@media (min-width: 51rem) {
  // 824px in rem units
  .event {
    padding: 5rem 5% 5rem 5%;
    h3 {
      span {
        font-size: 100px;
      }
    }
  }
}

@media (min-width: 90rem) {
  // 1440px in rem units
  .event {
    padding: 7.5rem 10% 7.5rem 10%;
  }
}
