$color_4: #2e3832;
$color_6: #dcdcdc;
$color_7: #232323;
$color_9: #3c3c3c;

.home {
  .hero-slider .swiper-pagination-bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2.71rem;
    height: 1rem;
    .swiper-pagination-bullet {
      &.swiper-pagination-bullet {
        /* transition for width and height */
        transition: scale 0.3s ease-in-out;
        scale: 0.75;
      }
      &.swiper-pagination-bullet-active {
        scale: 1;
      }
    }
  }
  .hero {
    background-color: var(--light-green-swiper-bg);
    padding: 1.25rem 0.8125rem; // 20px 13px
    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: 0.9375rem; // 15px
    }
    .hero-text {
      h2 {
        color: var(--black, #000);
        font-size: 2.5rem; // 40px
        font-style: normal;
        font-weight: 300;
        line-height: 2.75rem; // 44px
        margin-bottom: 2.5rem; // 40px
      }
      b {
        color: var(--black, #000);
        font-size: 2.5rem; // 40px
        font-style: normal;
        font-weight: 300;
        line-height: 2.75rem; // 44px
        color: var(--olive-green);
        font-weight: 700;
      }
      p {
        font-size: 1.375rem; // 22px
        font-weight: 400;
        line-height: 2.25rem; // 36px
        margin-bottom: 3.3125rem; // 53px
      }
      .btn {
        display: flex;
        justify-content: center;
        a {
          border-radius: 1.875rem; // 30px
          background: #137638;
          padding: 0.625rem 1.25rem; // 10px 20px
          color: var(--white, #fff);
          text-align: center;
          font-size: 1.25rem; // 20px
          font-style: normal;
          font-weight: 600;
          line-height: 2.25rem; // 36px
          margin-bottom: 5rem; // 80px
          cursor: pointer;
        }
      }
    }
  }
}
.no-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.filtre {
  display: flex;
  gap: 0.625rem; // 10px
  padding: 2rem 0.8125rem; // 32px 13px
  overflow-x: auto;
  background-color: var(--white, #fff);

  > button.loader,
  button.loader:hover {
    @include skeletonLoader;
    width: 170px;
    height: 50px;
    border: 0;
  }
  > button {
    padding: 0.625rem 2.5rem; // 10px 40px
    color: $color_4;
    font-family: var(--main-font);
    font-size: 1rem; // 16px
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; // 28px
    border-radius: 0.5rem; // 8px
    border: 0.0625rem solid #cdcdcd;
    background: var(--white, #fff);
    text-wrap: nowrap;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover,
    &.active {
      background: #137638;
      color: var(--white, #fff);
    }
  }
}
.products {
  padding: 0 0.8125rem; // 0px 13px
  background-color: transparent;
  overflow: visible;
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1.3125rem 0; // 0 0 21px 0
    .h3 {
      font-size: 1.25rem; // 20px
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.0075rem; // -0.12px
      text-transform: uppercase;
    }
    img {
      width: 2rem; // 32px
      height: 2rem; // 32px
      object-fit: cover;
      margin-right: 0.625rem; // 10px
    }
  }
  .products-list {
    display: flex;
    gap: 2.625rem; // 42px
    overflow-x: scroll;
    padding-bottom: 8.0625rem; // 121px
  }

  .more {
    display: none;
    justify-content: center;
    align-items: center;
    padding: 3.5rem 0; // 56px 0
    background-color: transparent;
    button {
      padding: 0.9375rem 3.8125rem; // 15px 61px
      color: $color_9;
      font-size: 1rem; // 16px
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 0.3125rem; // 5px
      border: 0.0625rem solid #d1d1d1;
      background-color: var(--white, #fff);
      cursor: pointer;
    }
  }
}
@media (min-width: 51rem) {
  // 824px in rem units
  .home {
    .hero {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding: 6.25rem 5% 6.25rem 5%; // 100px 5% 100px 5%
      .hero-image {
        height: 100%;
        margin-bottom: 0;
        padding-left: 2.8125rem; // 45px
        width: 100%;
        max-width: 35.9375rem; // 575px
      }
      .hero-text {
        .btn {
          justify-content: flex-start;
        }
      }
    }
  }
  .filtre {
    padding: 3.25rem 5% 3.25rem 5%; // 52px 5% 52px 5%
  }
  .products {
    padding: 0 5% 0 5%;
    .toolbar {
      padding: 0 0 2rem 0; // 0 0 32px 0
    }
    .products-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      justify-items: center;
      overflow: visible;
      padding-bottom: 0;
    }
    .more {
      display: flex;
    }
  }
}
@media (min-width: 90rem) {
  // 1440px in rem units
  .home {
    .hero {
      padding: 6.875rem 10% 6.875rem 10%; // 110px 10% 110px 10%
    }
  }
  .filtre {
    padding: 4.5rem 10% 4.5rem 10%; // 72px 10% 72px 10%
  }
  .products {
    padding: 0 10% 0 10%;
    .toolbar {
      padding: 0 0 3.25rem 0; // 0 0 52px 0
    }
  }
}
@media (min-width: 64rem) {
  // 1024px in rem units
  .products {
    .products-list {
      grid-template-columns: repeat(3, 1fr);
    }
    .more {
      padding: 4.75rem 0; // 76px 0
    }
  }
}
@media (min-width: 75rem) {
  // 1200px in rem units
  .products {
    .products-list {
      grid-template-columns: repeat(4, 1fr);
    }
    .more {
      padding: 6rem 0; // 96px 0
    }
  }
}
@media (min-width: 90rem) {
  // 1440px in rem units
  .filtre {
    justify-content: space-between;
  }
}
