@import "./list.scss";
@import "./footer.scss";
$text-black: #000;
.favoris_page {
  margin: 0 10%;
  h3 {
    color: $text-black;
    font-family: Jost;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.0075rem;
    text-transform: uppercase;
    margin-top: 5.06rem;
  }
}
@media (max-width: 768px), (max-width: 1024px) {
  .best_sale-toolbar-action {
    display: none;
  }
}
