$color_1: #232323;
$background-color_2: #ebebeb;

.header {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.8125rem; // 20px 13px
  &-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.0625rem solid #e5e5e5; // 1px solid #e5e5e5
    padding: 0 0 0.8125rem 0; // 0 0 13px 0
    &-block {
      display: flex;
      flex-direction: column;
      gap: 0.4375rem; // 7px
      &-line {
        display: flex;
        align-items: center;
        gap: 0.625rem; // 10px
        font-size: calc(0.75rem + 0.025vw); // calc(12px + 0.4vw)
        font-weight: normal;
        color: $color_1;
        img {
          width: calc(1rem + 0.025vw); // calc(16px + 0.4vw)
          height: calc(1rem + 0.025vw); // calc(16px + 0.4vw)
        }
      }
    }
  }

  &-bottom {
    display: flex;
    padding: 0.3125rem 0; // 5px 0
    &-logo {
      img {
        width: 7rem; // 64px
        height: 7rem; // 64px
        object-fit: cover;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      flex: auto;
      justify-content: center;
      &-search_group {
        display: none;
      }
      &-locale_group {
        display: flex;
        align-items: center;
        gap: 0.625rem; // 10px
        .country {
          display: flex;
          align-items: center;
          gap: 0.5rem; // 8px
        }
        img {
          width: calc(1rem + 0.025vw); // calc(16px + 0.4vw)
          height: calc(1rem + 0.025vw); // calc(16px + 0.4vw)
        }
        select {
          color: var(--text-light-green);
          font-size: calc(0.875rem + 0.025vw); // calc(14px + 0.4vw)
          font-weight: normal;
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
        }
      }
      &-user_info_group {
        display: none;

        &-badge {
          position: absolute;
          top: 0;
          right: 0;
          width: 1.25rem; // 20px
          height: 1.25rem; // 20px
          border-radius: 0.3125rem; // 5px
          background-color: var(--olive-green);
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white, #fff);
          font-size: 0.75rem; // 12px
          font-weight: 500;
        }
        &-dropdown {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          background-color: var(--white, #fff);
          border-radius: 0.625rem; // 10px
          min-width: 8.625rem; // 250px
          box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
          overflow: hidden;
          z-index: 1000;
          a {
            padding: 0.625rem 1.25rem; // 10px 20px
            font-size: 0.875rem; // 14px
            font-weight: 500;
            color: var(--text-light-green);
            &:hover {
              background-color: #f0f2f5;
            }
          }
        }
      }
    }
    &-mobile_menu {
      display: flex;
      align-items: center;
    }
  }
}

.bottom-nav-bar {
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $background-color_2;
  a {
    padding: 0.625rem 0; // 10px 0
  }
  img {
    width: 1.375rem; // 22px
    height: 1.375rem; // 22px
  }
}

@media (min-width: 26.5rem) {
  // 424px in rem units
  .header {
    padding: 1.25rem 8% 0.8125rem 8%; // 20px 8% 13px 8%
  }
}
@media (min-width: 51rem) {
  // 824px in rem units
  .header {
    padding: 1rem 5% 0.5rem 5%; // 16px 5% 8px 5%
    &-bottom {
      gap: 0.625rem; // 10px
      &-right {
        justify-content: flex-end;
        &-search_group {
          display: flex;
          align-items: flex-start;
          margin-right: 1.5625rem; // 25px
          width: 100%;
          max-width: 32.5rem; // 520px
          &-field {
            display: flex;
            position: relative;
            width: 100%;
            padding: 0.625rem 2.25rem 0.625rem 1.25rem; // 10px 36px 10px 20px
            align-items: flex-start;
            border-radius: 3.75rem; // 60px
            background: #f0f2f5;
            input {
              background: none;
              border: none;
              outline: none;
              height: 100%;
              width: 100%;
              color: var(--text-light-green);
              font-size: 0.875rem; // 14px
            }
            > img {
              position: absolute;
              right: 1.5625rem; // 25px
              top: 50%;
              transform: translateY(-50%);
              width: 1.25rem; // 20px
              height: 1.25rem; // 20px
            }
            &-results {
              position: absolute;
              top: calc(100% + 0.625rem); // calc(100% + 10px)
              left: 0;
              width: 100%;
              height: 12rem;
              background-color: #f0f2f5;
              border-radius: 0.625rem; // 10px
              min-width: 8.625rem; // 250px
              box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
              z-index: 1000;
              animation: growDown 0.3s ease-in-out forwards;
              transform-origin: top center;
              overflow-y: auto;
              .products_dropdown__item {
                display: flex;
                align-items: center;
                padding: 0.625rem 1.25rem; // 10px 20px
                font-size: 0.875rem; // 14px
                font-weight: 500;

                color: var(--liver);
                cursor: pointer;

                .category_name {
                  color: var(--text-light-green);
                  font-size: 0.65rem;
                }

                &:first-child {
                  border-radius: 0.625rem 0.625rem 0 0; // 10px 10px 0 0
                }

                &:last-child {
                  border-radius: 0 0 0.625rem 0.625rem; // 0 0 10px 10px
                }

                &:hover {
                  /* change bg color */
                  background-color: #dadada
                }

                img {
                  width: 2.25rem; // 20px
                  height: 2.25rem; // 20px
                  margin-right: 0.625rem; // 10px
                  overflow: hidden;
                }

                &--loader {
                  div {
                    height: 19.5px;
                    width: 100%;
                    border-radius: 10px;
                    @include skeletonLoader;
                  }
                }
              }

              &::before {
                content: '';
                position: absolute;
                top: -0.625rem; // -10px
                left: 50%;
                transform: translateX(-50%);
                border-left: 0.625rem solid transparent; // 10px
                border-right: 0.625rem solid transparent; // 10px
                border-bottom: 0.625rem solid #f0f2f5; // 10px
              }

              @keyframes growDown {
                0% {
                  transform: scaleY(0);
                }
                80% {
                  transform: scaleY(1.1);
                }
                100% {
                  transform: scaleY(1);
                }
              }
            }
          }
          button {
            display: flex;
            align-items: center;
            position: relative;
            gap: 0.3125rem; // 5px
            color: var(--white, #fff);
            padding: 0.5rem 1rem; // 8px 16px
            font-size: 0.875rem; // 14px
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem; // 24px
            border-radius: 4rem; // 64px
            border: none;
            background-color: var(--olive-green);
            outline: none;
            cursor: pointer;
            margin-right: 0.9375rem; // 15px
            .products_dropdown {
              position: absolute;
              top: calc(100% + 0.625rem); // calc(100% + 10px)
              left: 50%;
              translate: -50%;
              width: 100%;
              background-color: var(--olive-green);
              border-radius: 0.625rem; // 10px
              min-width: 8.625rem; // 250px
              box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
              z-index: 1000;
              animation: growDown 0.3s ease-in-out forwards;
              transform-origin: top center;
              .products_dropdown__item {
                padding: 0.625rem 1.25rem; // 10px 20px
                font-size: 0.875rem; // 14px
                font-weight: 500;
                color: var(--white, #fff);
                &:first-child {
                  border-radius: 0.625rem 0.625rem 0 0; // 10px 10px 0 0
                }
                &:last-child {
                  border-radius: 0 0 0.625rem 0.625rem; // 0 0 10px 10px
                }
                &:hover {
                  background-color: var(--olive-green-hover);
                }

                &--loader {
                  div {
                    height: 19.5px;
                    width: 100%;
                    border-radius: 10px;
                    @include skeletonLoader;
                  }
                }
              }

              &::before {
                content: '';
                position: absolute;
                top: -0.625rem; // -10px
                left: 50%;
                transform: translateX(-50%);
                border-left: 0.625rem solid transparent; // 10px
                border-right: 0.625rem solid transparent; // 10px
                border-bottom: 0.625rem solid var(--olive-green); // 10px
              }

              @keyframes growDown {
                0% {
                  transform: scaleY(0);
                }
                80% {
                  transform: scaleY(1.1);
                }
                100% {
                  transform: scaleY(1);
                }
              }
            }
          }
        }
        &-locale_group {
          margin-right: 1.5rem; // 24px
          select {
            font-size: 0.875rem; // 14px
          }
        }
        &-user_info_group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            position: relative;
          }
          img {
            width: 2rem; // 32px
            height: 2rem; // 32px
          }
          &-badge {
            width: 1rem; // 16px
            height: 1rem; // 16px
            font-size: 0.625rem; // 10px
          }
        }
      }

      > img {
        width: 5.9375rem; // 95px
        height: 5.9375rem; // 95px
        margin-right: calc(
          1.25rem + 3.4375 * ((100vw - 51rem) / (90 - 51))
        ); // calc(20px + 70 * ((100vw - 824px) / (1440 - 824)))
      }

      &-mobile_menu {
        display: none;
      }
    }
  }

  .bottom-nav-bar {
    display: none;
  }
}

@media (min-width: 90rem) {
  // 1440px in rem units
  .header {
    padding: 1.1875rem 10% 0.8125rem 10%; // 19px 10% 13px 10%
    &-bottom {
      gap: 1.25rem; // 20px
      > img {
        width: 7.6875rem; // 123px
        height: 7.6875rem; // 123px
      }
      &-right {
        &-search_group {
          margin-right: 3.125rem; // 50px
          max-width: 32.5rem; // 520px
          &-field {
            padding: 0.75rem 3.75rem 0.6875rem 1.5625rem; // 12px 60px 11px 25px
            input {
              color: var(--text-light-green);
              font-size: 1rem; // 16px
            }
            img {
              right: 1.5625rem; // 25px
              width: 1.25rem; // 20px
              height: 1.25rem; // 20px
            }
          }
          button {
            gap: 0.625rem; // 10px
            color: var(--white, #fff);
            padding: 0.625rem 1.5625rem; // 10px 25px
            font-size: 1.0625rem; // 17px
            border: none;
            margin-right: 0.9375rem; // 15px
          }
        }
        &-locale_group {
          margin-right: 1.875rem; // 30px
        }
      }
      &-user_info_group {
        img {
          width: 2.875rem; // 46px
          height: 2.875rem; // 46px
        }
        &-badge {
          width: 1.25rem; // 20px
          height: 1.25rem; // 20px
          font-size: 0.75rem; // 12px
        }
      }
    }
  }
}
