.not_found_page {
  text-align: center;
  margin: 3rem 0;

  .main-heading {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .gif {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.3rem;
    padding: 0.7rem 0;

    margin-bottom: 2rem;
  }
  a {
    color: var(--white, #fff);
  }

  button {
    padding: 0.69rem 3.92rem;
    border-radius: 3.75rem;
    background: #1e1e1e;
    color: var(--white, #fff);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    line-height: 1.5rem; /* 133.333% */
    cursor: pointer;
  }
}