@import "./section_payment.scss";
.payment_page {
  margin: 0 10%;
  &__header {
    margin-top: 2.94rem;
    margin-bottom: 4rem;
  }
  &__container {
    display: flex;
    gap: 0.44rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10.69rem;
  }
}
@media (max-width: 768px), (max-width: 1024px) {
  .payment_page {
    &__container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3.44rem;
    }
  }
}
