.services {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &__header {
    margin: 3rem 10% 0 10%;
    margin-top: 5.06rem;
    h1 {
      color: #000;
      font-family: Jost;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.0075rem;
      text-transform: uppercase;
    }
  }
  &__description {
    margin: 0 10% 0 10%;
    p:nth-child(1) {
      color: #000;
      font-family: Jost;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.0075rem;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
    p:nth-child(2) {
      color: #000;
      font-family: Jost;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    h1 {
      color: #000;
      font-family: Jost;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.0075rem;
      text-transform: uppercase;
    }
    select {
      width: 100%;
      padding: 12px 16px;
      color: var(--gray-500, #667085);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-radius: 8px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: var(--white, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
}
