$text-color: #2e3832;
@mixin line {
  height: 0.09375rem;
  background: #f7f9ef;
}
@mixin stock {
  font-family: Jost;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  text-transform: uppercase;
}
tr {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.favoris_page__list {
  &__table {
    overflow: scroll;
    height: 18.625rem;
    margin-top: 1.06rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.81rem 2.94rem 1.38rem 3rem;
    margin-bottom: 1.19rem;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    thead {
      tr {
        justify-content: space-around;
        th {
          max-width: 14rem;
          width: 100%;
          color: $text-black;
          font-family: Jost;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.0075rem;
          text-transform: uppercase;
        }
      }
      .line {
        margin-top: 1.62rem;
        @include line;
      }
    }
    tbody {
      margin-top: 1.25rem;
      tr {
        td {
          max-width: 14rem;
          width: 100%;
        }
        margin-bottom: 1.25rem;
        .delete {
          cursor: pointer;
        }
        .product-name {
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.06rem;
            img {
              width: 3.875rem;
              height: 4.125rem;
              object-fit: contain;
            }
            p {
              color: $text-color;
              font-family: Jost;
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2.25rem;
              flex-wrap: wrap;
            }
          }
        }
        .price {
          color: $text-color;
          font-family: Jost;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.25rem;
        }
        .stock {
          color: var(--text-light-green-caption);
          @include stock;
        }
        .out_of_stock {
          color: red;
          @include stock;
        }
        .btn {
          button {
            border-radius: 0.625rem;
            border: 1px solid #e4e4e4;
            background: var(--text-light-green-caption);
            color: #fff;
            text-align: center;
            font-family: Jost;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2.25rem;
            padding: 0.62rem;
            cursor: pointer;
          }
          button:hover {
            color: var(--text-light-green-caption);
            background: #fff;
            border: 1px solid var(--text-light-green-caption);
          }
        }
      }
      .line {
        @include line;
      }
    }
  }
}
.mobile-list {
  display: none;
}
@media (max-width: 768px), (max-width: 1024px) {
  .favoris_page__list {
    &__table {
      display: none;
    }
  }
  .mobile-list {
    position: relative;
    margin-top: 1.56rem;
    margin-bottom: 1.81rem;
    display: flex;
    height: 33.3125rem;
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.12rem 1.38rem 3rem 0.87rem;
    flex-direction: column;
    .delete {
      position: absolute;
      top: 1.12rem;
      right: 1.63rem;
      cursor: pointer;
    }
    .line {
      margin-top: 1.63rem;
      @include line;
    }
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 5.75rem;
        height: 6.0625rem;
        object-fit: contain;
      }
    }
    .name {
      margin-top: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p:nth-child(1) {
        color: #000;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0075rem;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        color: #2e3832;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
      }
    }
    .price {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p:nth-child(1) {
        color: #000;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0075rem;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        color: #2e3832;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
      }
    }
    .stock {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p:nth-child(1) {
        color: #000;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0075rem;
        text-transform: uppercase;
      }
      p:nth-child(2) {
        font-family: Jost;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        text-transform: uppercase;
      }
      .in_stock {
        color: var(--text-light-green-caption);
      }
      .out_of_stock {
        color: red;
      }
    }
    .btn {
      margin-top: 2.69rem;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 100%;
        border-radius: 0.625rem;
        border: 1px solid #e4e4e4;
        background: var(--text-light-green-caption);
        color: #fff;
        text-align: center;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.25rem;
        padding: 0.62rem;
        cursor: pointer;
      }
    }
  }
}
