$text-black: #000;
.verification_page__title {
  margin-top: 2.94rem;
  &__back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.29rem;
    span {
      width: 0.89288rem;
      height: 0.75306rem;
      cursor: pointer;
      color: var(--liver);
    }
    p {
      color: var(--liver);
      font-family: Manrope;
      font-size: 2.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 3rem;
    }
  }
  &__identify {
    p {
      color: $text-black;
      font-family: Manrope;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 3rem;
      span {
        color: var(--olive-green);
        font-family: Manrope;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3rem;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}
