.auth_layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  input,
  button,
  .phoneInput {
    width: 100% !important;
    padding: 14px 16px;
    max-width: 400px;
    color: var(--gray-500, #667085);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px !important;
    min-height: 48px;
    border: 1px solid var(--gray-300, #d0d5dd) !important;
    background: var(--white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .login_page {
    width: 100%;
    max-width: 400px;
    &__logo {
      display: flex;
      justify-content: center;
      margin-top: 64px;
    }

    &__form {
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        text-align: center;
        margin-top: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        margin: 8px 0 16px;
        color: #4a4a4a;
        text-align: center;
        margin: 8px 0 16px;
      }
      &__btn {
        button {
          background-color: var(--olive-green);
          color: #fff;
          font-weight: 500;
          margin-top: 42px;
          cursor: pointer;
        }
      }
    }
    .form-group {
      margin-top: 16px;
    }
  }
}
