.testimonials {
  display: flex;
  flex-direction: column;
  padding: 2.8125rem 0.875rem 2.8125rem 0.875rem;
  background-color: var(--white, #fff);
  gap: 4.25rem;
  position: relative;

  &-paginator {
    position: absolute;
    top: calc(100% - 4.5rem); // 
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    div {
      width: 13px;
      height: 13px;
      background-color: #12a05c;
      opacity: 0.3;
      border-radius: 50%;
      cursor: pointer;
    }
    div:hover {
      opacity: 1;
    }

    .active {
      opacity: 1;
      scale: 1.2;
    }
  }

  &-top {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    h3 {
      color: var(--gray-900, #101828);
      text-align: center;
      font-family: Inter;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.75rem; /* 122.222% */
      letter-spacing: -0.045rem;
    }

    h4 {
      color: var(--olive-green);
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem; /* 150% */
    }

    p {
      color: var(--gray-500, #667085);
      text-align: center;
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.875rem; /* 150% */
    }
  }

  &-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 4.25rem;
    gap: 3.75rem;
    &-item {
      display: flex;
      align-items: center;
      gap: 1.9375rem;
      padding: 2.875rem 0.6875rem;
      border-radius: 1.25rem;
      border: 0.0625rem solid #d8d8d8;
      background: var(--white, #fff);
      width: 100%;
      flex-shrink: 0;
      max-width: 27.1875rem;
      &-img {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0.0625rem solid #e2e2e2;
        background-color: #d9d9d9;
        flex-shrink: 0;
      }
      p {
        position: relative;
        color: var(--gray-500, #667085);
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        img:first-child {
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          top: -0.75rem;
          left: -1.6875rem;
        }

        img:last-child {
          position: relative;
          width: 1rem;
          height: 1rem;
          transform: translateY(50%) rotate(180deg) translateX(-25%);
        }

      }
    }
  }
}

@media (min-width: 51rem) {
  // 824px in rem units
  .testimonials {
    padding: 5rem 5% 5rem 5%;
  }
}

@media (min-width: 90rem) {
  // 1440px in rem units
  .testimonials {
    padding: 7.5rem 10% 7.5rem 10%;
  }
}
