@mixin label_style {
  color: #000;
  font-family: Mada;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.image {
  img {
    max-width: 3.6875rem;
    height: 1.625rem;
    object-fit: contain;
  }
  svg {
    width: 3.6875rem;
    height: 1.625rem;
  }
}
.payment_page__section {
  flex: auto;
  padding: 2.06rem 1.19rem 2.5rem 1.19rem;
  border-radius: 1.125rem;
  background: #fffffd;
  height: 50.125rem;
  &__content {
    &__method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2.19rem;
      margin: 1.5rem 2.62rem 7.88rem 2.75rem;

      .wave,
      .orange_money,
      .mtn_money,
      .mastercard {
        input {
          margin-right: 1.44rem;
        }
        label {
          @include label_style;
        }
        display: flex;
        justify-content: space-between;
      }
    }
    &__form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.18rem;
      gap: 1.37rem;
      .column1,
      .column2 {
        display: flex;
        flex-direction: column;
        gap: 1.81rem;
      }
      .column1 {
        flex: auto;
        input {
          width: 100%;
        }
      }
      .column2 {
        input {
          width: 8.6875rem;
        }
      }
      .form-group {
        margin-left: auto;
        margin-right: auto;
      }
      .form-group {
        margin-bottom: 16px;
        position: relative;
      }
      input {
        position: relative;
        display: block;
        border-radius: 0.5rem;
        border: 1px solid #efe7ec;
        background-color: transparent;
        margin: 0px auto;
        padding: 6px 4px 4px 14px;
        height: 3.0625rem;
        outline: none !important;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        transition: all 0.2s ease-in-out;
      }

      label {
        position: absolute;
        top: 20px;
        left: 12px;
        text-align: left;
        display: inline-block;
        padding: 0 4px;
        height: 14px;
        color: var(--liver);
        font-family: Manrope;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: #fff;
        margin: 0px auto;
        cursor: text;
        transition: all 0.15s ease-in-out;
      }
      input:hover,
      input:focus {
        border: 1px solid #000;
      }

      input:valid + label,
      input:focus + label {
        top: -6px;
        color: #000;
        font-weight: bold;
      }
      .gl-form-asterisk {
        background-color: inherit;
        color: #e32b2b;
        padding: 0;
        padding-left: 3px;
      }
      .gl-form-asterisk:after {
        content: "*";
      }
      &__btn {
        margin-top: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          cursor: pointer;
          width: 100%;
          padding: 1rem 0;
          color: #fff;
          font-family: Manrope;
          font-size: 1.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.75rem;
          border-radius: 0.75rem;
          border: 0;
          background: linear-gradient(
            180deg,
            #137638 0%,
            #12a05c 42.71%,
            #12a05c 100%
          );
          box-shadow: 0px 3.6px 4.7125px 0px rgba(23, 16, 21, 0.05),
            0px 18px 29px 0px rgba(23, 16, 21, 0.03);
        }
      }
    }
  }
}
@media (max-width: 768px), (max-width: 1024px) {
  .payment_page__section {
    height: auto;
    &__content {
      &__method {
        margin-left: 0;
        margin-right: 0;
      }
      &__form {
        .form-group {
          max-width: 100%;
        }
        display: block;
        width: 100%;
        .column1 {
          flex: auto;
          input {
            width: 100%;
          }
        }
        .column2 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
