$text-white: #fffffd;
$line_color: #efe7ec;
@mixin container_style {
  border-radius: 1.125rem;
  background: $text-white;
}
.verification_page__info_customer {
  display: flex;
  justify-content: space-between;
  gap: 0.44rem;
  margin: 4rem 0 10.69rem 0;
  &__verify {
    flex: auto;
    padding: 2.06rem 2.06rem 2.5rem 2.25rem;
    @include container_style;
    height: 50.125rem;
    &__header {
      display: flex;
      justify-content: center;
      gap: 4.13rem;
      margin-bottom: 2.55rem;
      p:nth-child(1) {
        color: var(--liver);
        text-align: center;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 125% */
      }
      p {
        color: #4f4f4f;
        font-family: Manrope;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 125% */
      }
    }
    &__line {
      div {
        height: 0.0625rem;
        background: $line_color;
      }
    }
    &__body {
      &__title {
        margin-top: 2rem;
        p {
          color: #4f4f4f;
          font-family: Manrope;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.75rem;
        }
      }
      &__content {
        &__form {
          p {
            color: var(--liver);
            font-family: Manrope;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            margin-bottom: 0.5rem;
          }
          input {
            border-radius: 0.5rem;
            border: 1px solid #efe7ec;
            background: rgba(255, 255, 255, 0.6);
            padding: 0.875rem 1.25rem;
            width: 21.25rem;
          }
          &__name_info {
            margin-top: 1rem;
            display: flex;
            gap: 1.62rem;
          }
          &__contact_info {
            margin-top: 1.25rem;
            display: flex;
            gap: 1.62rem;
          }
          &__deliver_title {
            margin-top: 2rem;
            p {
              color: var(--liver);
              font-family: Manrope;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.75rem;
            }
          }
          &__deliver_info {
            margin-top: 1rem;
            display: flex;
            gap: 1.62rem;
          }
          &__address_info {
            margin-top: 1.25rem;
            display: flex;
            gap: 2.5rem;
            &__town {
              select {
                width: 9.1875rem;
                border-radius: 0.5rem;
                border: 1px solid #efe7ec;
                background: rgba(255, 255, 255, 0.6);
                padding: 0.875rem 1.25rem;
              }
            }
            &__address {
              flex: auto;
              input {
                width: 100%;
              }
            }
            &__zip {
              input {
                width: 9.1875rem;
              }
            }
          }
          &__payment {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3.75rem;
            button {
              width: 100%;
              padding: 1rem 0;
              color: #fff;
              font-family: Manrope;
              font-size: 1.375rem;
              font-style: normal;
              font-weight: 700;
              line-height: 1.75rem;
              border-radius: 0.75rem;
              cursor: pointer;
              background: linear-gradient(
                180deg,
                #137638 0%,
                #12a05c 42.71%,
                #12a05c 100%
              );
              box-shadow: 0px 3.6px 4.7125px 0px rgba(23, 16, 21, 0.05),
                0px 18px 29px 0px rgba(23, 16, 21, 0.03);
            }
          }
        }
      }
    }
  }
  &__commande {
    &::-webkit-scrollbar {
      background: transparent;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--text-light-green-caption);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    overflow-y: scroll;
    @include container_style;
    height: 50.125rem;
    padding: 1.94rem 2.94rem 5.12rem 2.19rem;
    &__header {
      p {
        color: var(--liver);
        font-family: Manrope;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem; /* 133.333% */
      }
    }
    &__line {
      margin-top: 1.12rem;
      div {
        height: 0.0625rem;
        background: $line_color;
      }
    }
    &__products {
      margin-top: 3.25rem;
      &__product {
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            color: var(--liver);
            font-family: Jost;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem;
          }
          svg {
            width: 1.14881rem;
            height: 1.3125rem;
            color: var(--avocado);
            cursor: pointer;
          }
        }
        &__body {
          margin-top: 3.21rem;
          display: flex;
          gap: 2.19rem;
          &__image {
            width: 3.875rem;
            height: 4.125rem;
            img {
              object-fit: contain;
            }
          }
          &__info {
            flex: auto;
            p {
              color: #2e3832;
              font-family: Jost;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25rem;
            }
          }
          &__quantity {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;
            p:nth-child(1) {
              color: var(--liver);
              font-family: Manrope;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            p:nth-child(2) {
              margin-top: 0.5rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 0.94rem;
              span {
                color: var(--liver);
                font-family: Manrope;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              svg {
                cursor: pointer;
              }
            }
          }
        }
        &__price {
          margin-top: 1.56rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            color: var(--liver);
            font-family: Manrope;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
          }
        }
        &__line {
          margin-top: 2.13rem;
          div {
            height: 0.0625rem;
            background: $line_color;
          }
        }
      }
    }
    &__total {
      margin-top: 4.38rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p:nth-child(1) {
        color: var(--liver);
        font-family: Manrope;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
      p:nth-child(2) {
        color: var(--liver);
        font-family: Manrope;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 800;
        line-height: 1.5rem;
      }
    }
  }
}
@media (max-width: 768px), (max-width: 1024px) {
  .verification_page__info_customer {
    flex-direction: column;
    justify-content: center;
    &__verify {
      height: auto;
      &__header {
        gap: 1.5rem;
      }
      &__body {
        &__content {
          &__form {
            &__name_info {
              flex-direction: column;
              input {
                width: 100%;
              }
            }
            &__contact_info {
              flex-direction: column;
              input {
                width: 100%;
              }
            }
            &__deliver_info {
              flex-direction: column;
              input {
                width: 100%;
              }
            }
            &__address_info {
              flex-direction: column;
              &__town {
                select {
                  width: 100%;
                }
              }
              &__address {
                flex: auto;
                input {
                  width: 100%;
                }
              }
              &__zip {
                input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    &__commande {
      &__products {
        &__product {
          &__body {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
