.best_sale {
  padding: 1.25rem 0.875rem 6.25rem 0.875rem; /* 20px 14px 100px 14px */
  background-color: #f7f9ef;

  &-ads {
    display: none;
  }

  &-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.0625rem; /* 17px */
    &-action {
      display: flex;
      gap: 0.1875rem; /* 3px */
      &-prev,
      &-next {
        width: 1.5rem; /* 24px */
        height: 1.5rem; /* 24px */
        border-radius: 0.3125rem; /* 5px */
        background-color: #d7d7d7;
        border: 0;
        color: var(--white, #fff);
        cursor: pointer;
      }
    }
  }

  &-products {
    &-big_item {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.875rem; /* 30px */
      width: 100%;
      height: 664px;
      background-color: var(--white, #fff);
      padding: 3.5625rem 1.4375rem; /* 57px 23px */
      align-self: stretch;
      &.loader {
        h4 {
          height: 483px;
          @include skeletonLoader;
          border-radius: 4px; /* 10px */
        }
        .best_sale-products-big_item-img {
          @include skeletonLoader;
           border-radius: 4px; /* 10px */
        }
        a {
          @include skeletonLoader;
          border-radius: 4px; /* 10px */
        }
      }

      h4 {
        text-align: center;
        font-family: Manrope;
        font-size: 1.875rem; /* 30px */
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.01125rem; /* -0.18px */
        margin-bottom: 2.8125rem; /* 45px */
      }

      &-img {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          position: absolute;
          width: 12rem;
          height: 12rem;
          object-fit: fill;
        }
        img:first-child {
          top: 0;
          left: 0;
        }
        img:nth-child(2) {
          top: 0;
          right: 0;
        }
        img:nth-child(3) {
          bottom: 0;
          left: 50%;
          transform: translateX(-100%);
        }
        img:nth-child(4) {
          bottom: 0;
          right: 50%;
          transform: translateX(100%);
        }
      }

      &-btn {
        display: flex;
        justify-content: center;
        a {
          border-radius: 1.875rem; /* 30px */
          background: var(--olive-green);
          padding: 0.625rem 1.25rem; /* 10px 20px */
          color: var(--white, #fff);
          text-align: center;
          font-size: 1.25rem; /* 20px */
          font-style: normal;
          font-weight: 600;
          line-height: 2.25rem; /* 36px */ /* 180% */
          border: 0;
          margin-top: 4.5rem; /* 72px */
        }
      }
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: 1.875rem; /* 30px */
    }
  }
}

@media (min-width: 36rem) {
  /* 576px in rem units */
  .best_sale {
    padding: 1.25rem 5% 6.25rem 5%; /* 20px 5% 100px 5% */

    &-products {
      &-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.875rem; /* 30px */
      }
    }
  }
}

@media (min-width: 51.5rem) {
  /* 824px in rem units */
  .best_sale {
    padding: 2.5rem 5% 6.25rem 5%; /* 40px 5% 100px 5% */

    &-products {
      &-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.875rem; /* 30px */
      }

      &-ads {
        display: flex;
      }
    }
  }
}

@media (min-width: 64rem) {
  /* 1024px in rem units */
  .best_sale {
    padding: 2.5rem 5% 6.25rem 5%; /* 40px 5% 100px 5% */

    &-products {
      display: flex;
      gap: 1.875rem; /* 30px */
      &-big_item {
        width: 26.25rem; /* 420px */
        height: 774px;
        margin-bottom: 0;
        border-radius: 0.625rem; /* 10px */
        box-shadow: 0 1.25rem 4.25rem 0 rgba(0, 0, 0, 0.1);
        h4 {
          font-size: 2.5rem; /* 40px */
        }
      }
      &-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.875rem; /* 30px */
        flex: auto;
      }
    }
  }
}

@media (min-width: 75rem) {
  /* 1200px in rem units */
  .best_sale {
    &-products {
      gap: 1.875rem; /* 30px */
      &-big_item {
        width: 35.375rem; /* 566px */
      }
    }
  }
}

@media (min-width: 90rem) {
  /* 1440px in rem units */
  .best_sale {
    padding: 3.125rem 10% 3.125rem 10%; /* 50px 10% 50px 10% */
  }
}
