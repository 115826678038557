.contact {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2.5rem;
  //padding: 2.8125rem 0.875rem 2.8125rem 0.875rem;

  &-img {
    width: 100%;
    position: relative;
    div {
      width: 100%;
      height: 273px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &-borderTop {
      width: 129px;
      height: 113px;
      position: absolute;
      top: -24px;
      right: -24px;
      display: none;
    }

    &-borderBottom {
      width: 129px;
      height: 113px;
      position: absolute;
      bottom: -24px;
      right: -34px;
      display: none;
    }
  }

  &-form {
    padding: 2.1875rem;
    background-color: var(--white, #fff);
    h3 {
      color: var(--gray-900, #101828);
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px; /* 169.231% */
      letter-spacing: -0.52px;
      margin-bottom: 12px;
    }
    > p {
      color: var(--gray-500, #667085);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
    }

    form {
      display: flex;
      flex-direction: column;
      margin-top: 26px;
      gap: 24px;

      > div {
        display: flex;
        justify-content: space-between;
        gap: 32px;
        width: 100%;
      }
    }

    &-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 6px;

      label {
        color: var(--gray-700, #344054);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 6px;
      }

      input[type="text"],
      input[type="number"],
      input[type="email"],
      input[type="date"],
      input[type="time"],
      textarea {
        width: 100%;
        padding: 12px 16px;
        color: var(--gray-500, #667085);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      &--phone {
        display: flex;
        background-color: var(--white, #fff);
        input[type="number"] {
          border-left: 0;
          padding-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        select {
          border-radius: 8px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border: 1px solid var(--gray-300, #d0d5dd);
          border-right: 0;
          background: var(--white, #fff);
          padding: 12px 24px 12px 16px;
          background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
            no-repeat;
          background-position: right 10px top 50%;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
        }
      }
    }

    &-politique {
      display: flex;
      align-items: center;
      gap: 12px !important;
      justify-content: flex-start !important;
      margin-bottom: 32px;

      input {
        width: 20px;
        height: 20px;
      }

      p {
        color: var(--gray-500, #667085);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }

    &-submit {
      button {
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--olive-green);
        background: var(--olive-green);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--white, #fff);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }
}

@media (min-width: 30rem) {
  // 480px in rem units
  .contact {
    padding: 3.75rem 5% 3.75rem 5%;
    &-img {
      div {
        height: 400px;
      }
    }
  }
}

@media (min-width: 51rem) {
  // 824px in rem units
  .contact {
    padding: 5rem 5% 5rem 5%;
  }
}

@media (min-width: 64rem) {
  // 1024px in rem units
  .contact {
    flex-direction: row;
    justify-content: space-between;
    > div {
      flex: 1;
    }
    &-form {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    &-img {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      > div {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }

      div {
        height: 100%;
      }

      &-borderTop {
        display: block;
      }

      &-borderBottom {
        display: block;
      }
    }
  }
}

@media (min-width: 90rem) {
  // 1440px in rem units
  .contact {
    padding: 7.5rem 10% 7.5rem 10%;
  }
}
