@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Jost:wght@200;300;400;500;600;700&family=Mada&family=Manrope:wght@400;500;600;700;800&family=Roboto:wght@400;500&display=swap");
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
  outline-color: #88b825;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}

hr {
  border: 1px solid #e9e9e9;
}

html {
  max-width: 2000px;
  margin: 0 auto;
}

body {
  overflow-x: hidden;
}

#root {
  width: 100%;
  min-height: 100vh;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.success {
  color: green;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.padding {
  padding: 1rem;
}
.error {
  color: red;
  font-weight: 500;
}

.relative {
  position: relative;
}

.disabled {
  background-color: #e4e4e4 !important;
  color: #b0d1bd !important;
}

input[arial-error] {
  border: 1px solid red;
}

@mixin skeletonLoader {
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

button:disabled {
  background-color: #e4e4e4 !important;
  color: #b0d1bd !important;
  cursor: not-allowed !important;
}

.index_layout {
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex: auto;
    background-color: #f7f9ef;
    width: 100%;
    margin: 0 auto;
  }
}
.may_like_title {
  color: #000;
  font-family: Jost;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.0075rem;
  text-transform: uppercase;
}
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/home.scss";
@import "./components/best_sale.scss";
@import "./components/ad.scss";
@import "./components/testimonials.scss";
@import "./components/event.scss";
@import "./components/contact.scss";
@import "./pages/verify-page/verify.scss";
@import "./pages/favoris-page/favoris_page.scss";
@import "./pages/payment/payment.scss";
@import "./pages/payment/payment_confirmation";
@import "./pages/auth/login";
@import "./pages/service-pages/service.scss";
@import "./pages/404/404";

.card_infos {
  display: none;
  justify-content: center;
  gap: 34px;
  padding: 56px 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 10px;
    background-color: var(--white, #fff);
    color: #137638;
    font-style: normal;
    font-weight: 600;
    padding: 24px 32px;
    gap: 18px;
    font-size: 18px;
    line-height: 20px;
    img {
      width: 53px;
      height: 53px;
    }
  }
}

@media (min-width: 51rem) {
  // 824px in rem units
  .card_infos {
    display: flex;
    padding: 3rem 5% 0 5%;
  }
}

@media (min-width: 70rem) {
  // 1120px in rem units
  .card_infos {
    div {
      padding: 32px 52px;
      gap: 30px;
      font-size: 25px;
      line-height: 28px; /* 112% */
      img {
        width: 73px;
        height: 73px;
      }
    }
  }
}

@media (min-width: 90rem) {
  // 1440px in rem units
  .card_infos {
    padding: 4.5rem 10% 0 10%;
  }
}

.product.loader {
  padding: 0;
  overflow: hidden;
  .text {
    padding: 0 12px;
  }
  .image {
    height: 265px;
    @include skeletonLoader;
  }
  .title {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    @include skeletonLoader;
  }
  .price {
    &-loader {
      display: flex;
      justify-content: space-between;
      @include skeletonLoader;
      width: 100px;
      height: 30px;
      border-radius: 4px;
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  flex-shrink: 0;
  min-width: 16rem;
  max-width: 20rem;
  width: 100%;
  height: 23.0625rem;
  background-color: var(--white, #fff);
  border-radius: 10px;
  box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 21px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      &:first-child {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .stock {
      color: var(--text-light-green-caption);
    }

    .out_of_stock {
      color: var(--text-light-red-caption, #ff0000);
    }
  }
  .image {
    display: flex;
    justify-content: center;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
      max-width: 10.3125rem;
      max-height: 11rem;
      height: 155px;
      object-fit: fill;
    }
  }
  .title {
    cursor: pointer;
    > div {
      &:first-child {
        color: var(--text-light-green-caption);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 3px;
      }
      &:last-child {
        a {
          color: var(--black, #000);
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          max-width: 209px;
          width: 100%;
          text-wrap: wrap;
        }
        a:hover {
          color: var(--olive-green);
        }
      }
    }
  }

  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 9px;
    gap: 25px;
    margin-bottom: 13px;
    > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        gap: 1px;
        > div {
          &:first-child {
            color: $color_6;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: strikethrough;
          }
          &:last-child {
            color: $color_7;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
      &:last-child {
        flex-shrink: 0;
        button {
          border-radius: 4px;
          background: #137638;
          padding: 6px 11px;
          color: var(--white, #fff);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: 0;
          cursor: pointer;
          flex-shrink: 0;
        }
      }
    }
  }
}

.details_desplay {
  margin: 5% 10%;
  display: flex;
  flex-direction: column;
  .product_details {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    @media (max-width: 768px), (max-width: 1024px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .col1 {
      display: flex;
      flex-direction: column;
      flex: auto;
      .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26.125rem;
        flex-direction: column;
        background: #fff;
        padding: 3.87rem 5.09rem 5.125rem 5.09rem;
        @media (max-width: 768px) {
          // width: 100%;
          // height: 357px;
        }
        img {
          width: 16.5625rem;
          height: 100%;
          object-fit: contain;
          background-color: white;
        }
      }
      .thumbnails {
        display: flex;
        margin-top: 0.75rem;
        gap: 0.75rem;
        margin-left: 0.75rem;
        flex-wrap: wrap;
        img {
          cursor: pointer;
          justify-content: center;
          width: 4.625rem;
          height: 4rem;
          max-width: 4.625rem;
          object-fit: contain;
          border-radius: 0.25rem;
          border: 1px solid #2e3832;
        }
      }
    }
    .col2 {
      display: flex;
      flex-direction: column;
      .heading {
        display: flex;
        flex-direction: column;
        .stock {
          span {
            padding: 0rem 0.59875rem 0rem 0.625rem;
            color: #fff;
            font-family: Jost;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.125rem;
            text-transform: uppercase;
            border-radius: 1.25rem;
            &.out_of_stock {
              background: #ff0000;
            }
            &.in_stock {
              background: #12a05c;
            }
          }
        }
        .video_link {
          margin-top: 2.38rem;
          text-align: left;
          display: flex;
          justify-items: center;
          align-items: center;
          gap: 0.69rem;
          svg {
            width: 1.24994rem;
            height: 0.99994rem;
            color: #5b6c8f;
          }
          h2 {
            a {
              color: #5b6c8f;
              font-family: Jost;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.25rem;
              text-decoration-line: underline;
            }
          }
        }
        .name {
          margin-top: 0.75rem;
          color: #2e3832;
          font-family: Jost;
          font-size: 1.625rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.25rem;
        }
      }
      .line {
        margin-top: 2.19rem;
        height: 0.15rem;
        background-color: white;
      }
      .description {
        margin-top: 1.12rem;
        display: flex;
        flex-direction: column;
        .one_line {
          display: flex;
          flex-direction: column;
          gap: 0.37rem;
          h3 {
            color: #2e3832;
            font-family: Jost;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
          }
        }
      }
      .quantities {
        display: flex;
        flex-direction: column;
        .price {
          margin-top: 1.12rem;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5.07rem;
          h2 {
            color: #137638;
            font-family: Jost;
            font-size: 2.375rem;
            font-style: normal;
            font-weight: 500;
            line-height: 3.125rem; /* 131.579% */
            @media (max-width: 1441px) {
              font-size: 2rem;
            }
          }
          svg {
            cursor: pointer;
            width: 1.8125rem;
            height: 1.5625rem;
            &.inactive {
              color: #000;
            }
            &.active {
              color: red;
            }
          }
        }
        .quantity {
          margin-top: 3rem;
          display: flex;
          flex-direction: column;
          h3 {
            margin-top: 0.37rem;
            color: #2e3832;
            font-family: Jost;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
          }
          .quantity_input_container {
            display: flex;
            gap: 0.62rem;
            .quantity_input {
              display: flex;
              border-radius: 3.75rem;
              overflow: hidden;
              box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
              background: #f0f2f5;
              button {
                background: var(--porcelain);
                padding: 0 0.5rem;
                color: black;
                border: none;
                cursor: pointer;
                font-size: 1.25rem;
                height: auto;
                text-align: center;
                transition: background-color 0.2s;
              }
              .input-box {
                background-color: var(--porcelain);
                text-align: center;
                border: none;
                padding: 0 1.88rem;
                font-size: 1rem;
                outline: none;
              }
              .input-box::-webkit-inner-spin-button,
              .input-box::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              .input-box[type="number"] {
                appearance: textfield;
              }
            }
            .button {
              flex: auto;
              display: flex;
              justify-content: center;
              align-items: center;
              button {
                width: 100%;
                color: #fff;
                text-align: center;
                font-family: Jost;
                font-size: 0.9rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                border-radius: 3.75rem;
                border: 1px solid #137638;
                background: #137638;
                padding: 0.6875rem 1rem 0.6875rem 1rem;
                cursor: pointer;
                @media (max-width: 1441px) {
                  font-size: 0.7rem;
                }
              }
            }
            .button:hover {
              color: var(--text-light-green-caption);
            }
          }
        }
      }
    }
    .col3 {
      display: flex;
      flex-direction: column;
      border-radius: 0.875rem;
      border: 1px solid #e9e9e9;
      background: #fff;
      padding: 1.75rem 1.75rem 2.19rem 1.75rem;
      height: 21.69rem;
      .top {
        display: flex;
        flex-direction: column;
        gap: 1.06rem;
        .one_line {
          display: flex;
          align-items: center;
          gap: 0.98rem;
          img {
            width: 2.20769rem;
            height: 0.89894rem;
            background-color: white;
          }
          p {
            color: #232323;
            font-family: Jost;
            font-size: 0.9375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.75rem; /* 186.667% */
          }
        }
      }
      .line {
        margin-top: 2.04rem;
        height: 0.09375rem;
        background: #d9d9d9;
      }
      .bottom {
        margin-top: 1.99rem;
        background-color: white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1.84rem;
        row-gap: 2.4rem;
        img {
          object-fit: cover;
        }
      }
    }
  }
  .description {
    margin-top: 5.63rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .title {
      display: flex;
      gap: 20%;
      h3 {
        color: #000;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.0075rem;
        text-decoration-line: underline;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
      p {
        color: #000;
        font-family: Jost;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.0075rem;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    .description_text {
      color: #000;
      font-family: Jost;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.cart_display {
  margin: 5% 10%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      justify-content: center;
    }
    h2 {
      color: #000;
      font-family: Jost;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
      text-transform: uppercase;
    }
    .actions {
      margin-right: 15px;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        display: none;
      }
      img {
        width: 100%;
        height: 80px;
        margin: 0 -20px;
        cursor: pointer;
      }
    }
  }
  .cart_items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    @media (max-width: 768px) {
      place-items: center;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
    .main_cart_item {
      position: relative;
      border-radius: 10px;
      grid-row: span 2;
      grid-column: span 2;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #ccc;
      height: 777px;
      width: 90%;
      @media (max-width: 768px) {
        grid-row: span 1;
        grid-column: span 1;
        width: 90%;
        height: 684px;
      }
      img {
        margin-top: 30%;
        background-color: white;
        @media (max-width: 768px) {
          width: 200px;
          height: 250px;
        }
      }
      .title {
        font-weight: 400;
        font-size: 40px;
        line-height: 54.64px;
        text-align: center;
        background-color: transparent;
        position: absolute;
        top: 10%;
        left: 0;
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 27.32px;
          top: 5%;
        }
      }
      .print {
        position: absolute;
        bottom: 20px;
        left: 20px;
        @media (max-width: 768px) {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
      .add-to-cart {
        font-size: 20px;
        font-weight: 600;
        line-height: 17.34px;
        background-color: var(--olive-green);
        color: #fff;
        border: none;
        padding: 16px;
        margin-top: 10px;
        cursor: pointer;
        border-radius: 30px;
        transition: background-color 0.3s;
      }

      .add-to-cart:hover {
        background-color: var(--text-light-green-caption);
      }
    }
    .cart_item {
      display: flex;
      border-radius: 10px;
      flex-direction: column;
      border: 1px solid #ccc;
      margin: 10px;
      padding: 20px;
      width: 90%;
      height: 369px;
      background-color: white;
      img {
        height: 130px;
        width: 165px;
        max-width: 200px;
        margin: auto;
        background-color: white;
      }
      .cart_item_description {
        margin: 30px 0 0 0;
        background-color: #fff;
        .numero {
          text-align: left;
          font-weight: bold;
          background-color: #fff;
          color: var(--text-light-green-caption);
          font-weight: 400;
          font-size: 13px;
          line-height: 14.45px;
        }

        .name {
          text-align: left;
          margin-top: 5px;
          font-size: 1.2em;
          background-color: #fff;
          font-weight: 700;
          font-family: Jost;
          font-size: 16px;
          line-height: 18.79px;
        }
        .bottom {
          background-color: white;
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          .price {
            text-align: left;
            font-weight: 700;
            line-height: 20.23px;
            font-size: 16px;
            margin-top: 10px;
            background-color: #fff;
          }

          .add-to-cart {
            font-size: 10px;
            font-weight: 500;
            line-height: 17.34px;
            background-color: var(--olive-green);
            color: #fff;
            border: none;
            padding: 5px 10px;
            margin-top: 10px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          .add-to-cart:hover {
            background-color: var(--text-light-green-caption);
          }
        }
      }
    }
  }
}

.likes_display {
  display: flex;
  flex-direction: column;
  h2 {
    color: #000;
    font-family: Jost;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
    text-transform: uppercase;
  }

  .listing {
    border-radius: 10px;
    background: #fff;
    display: grid;
    grid-template-columns: 5% 15% repeat(4, 1fr);
    .frist {
      grid-column: span 3;
    }
    .last {
      grid-column: 2;
    }
    p {
      color: #2e3832;
      font-family: Jost;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 180% */
    }
    button {
      border-radius: 10px;
      border: 1px solid #e4e4e4;
      background: #12a05c;
      color: #fff;
      text-align: center;
      font-family: Jost;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 180% */
    }
  }
  .characteristics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5px;
    img {
      width: 19.747px;
      height: 23px;
    }
    p {
      color: #232323;
      font-family: Jost;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 186.667% */
    }
  }
}

.validation_display {
  display: flex;
  flex-direction: column;
  gap: 64px;
  .title {
    display: flex;
    img {
      width: 14.286px;
      height: 12.049px;
    }
    .txt {
      h2 {
        color: #4f4f4f;
        font-family: Manrope;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 126.316% */
      }
      h3 {
        color: #000;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 240% */
        span {
          color: #137638;
          font-family: Manrope;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 48px;
          text-decoration-line: underline;
        }
      }
    }
  }
  .details {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 7px;
    .left {
      border-radius: 18px;
      background: #fffffd;
      mix-blend-mode: overlay;
      box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.11),
        0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.18),
        0px 20px 13px 0px rgba(0, 0, 0, 0.23),
        0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.28);

      display: flex;
      flex-direction: column;
    }

    .right {
      border-radius: 18px;
      background: #fffffd;
      mix-blend-mode: overlay;
      box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.11),
        0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.18),
        0px 20px 13px 0px rgba(0, 0, 0, 0.23),
        0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.28);
    }
  }
}
.shop {
  h3 {
    color: #000;
    font-family: Jost;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.0075rem;
    text-transform: uppercase;
  }
  margin: 5% 10%;
  .shopping {
    h2 {
      padding: 3% 3%;
      display: flex;
      width: 280px;
      height: 36px;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      color: #000;
      font-family: Jost;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.12px;
      text-transform: uppercase;
      @media (max-width: 768px) {
        font-size: 10px;
        flex-direction: row;
        margin: 0 auto;
      }
      @media (max-width: 1024px) {
        font-size: 10px;
      }
    }
    .panier-container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .panier {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        @media (max-width: 768px) {
          width: 100%;
        }
        @media (max-width: 1024px) {
          width: 60%;
        }
        @media (max-width: 426px) {
          width: 100%;
        }
        .item {
          overflow-y: scroll;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          background: #fff;
          height: 18.625rem;
          &::-webkit-scrollbar {
            background: transparent;
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #137638;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          @media (max-width: 1024px) {
            width: 340px;
          }
          .item-header {
            display: flex;
            justify-content: space-around;
            margin: 0 10px;
            p {
              margin: 3% 0;
              color: #000;
              font-family: Jost;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: -0.12px;
              @media (max-width: 768px), (max-width: 1024px) {
                font-size: 10px;
              }
            }
          }
          .one_line {
            border-style: solid;
            border-color: #f7f9ef;
            border-width: 1.5px 0 0 0;
            width: 783px;
            height: 0px;
            position: relative;
            transform-origin: 0 0;
            transform: rotate(0deg) scale(1, 1);
          }
          .item-contents {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin: 3% 0;
            justify-content: space-around;
            .delete {
              fill: #000;
              cursor: pointer;
              @media (max-width: 768px), (max-width: 1024px) {
                width: 20px;
                height: 20px;
              }
            }
            img {
              width: 62px;
              height: 66px;
              @media (max-width: 768px), (max-width: 1024px) {
                width: 30px;
                height: 30px;
              }
            }
            .name {
              color: #2e3832;
              font-family: Jost;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px; /* 180% */
              @media (max-width: 768px), (max-width: 1024px) {
                font-size: 10px;
              }
            }
            .prix {
              color: #2e3832;
              font-family: Jost;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px; /* 180% */
              @media (max-width: 768px), (max-width: 1024px) {
                font-size: 10px;
              }
            }
            .quantity_input {
              display: flex;
              border-radius: 20px;
              width: auto;
              height: 30px;
              overflow: hidden;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              @media (max-width: 768px), (max-width: 1024px) {
                width: 4.25rem;
                height: 20px;
              }
              button {
                background-color: #f0f2f5;
                color: black;
                border: none;
                cursor: pointer;
                font-size: 20px;
                width: 30px;
                height: auto;
                text-align: center;
                transition: background-color 0.2s;
                @media (max-width: 768px), (max-width: 1024px) {
                  font-size: 10px;
                }
                @media (max-width: 426px) {
                  font-size: 8px;
                }
              }
              .input-box {
                background-color: #f0f2f5;
                width: 40px;
                text-align: center;
                border: none;
                padding: 8px 10px;
                font-size: 10px;
                outline: none;
              }
              .input-box::-webkit-inner-spin-button,
              .input-box::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              .input-box[type="number"] {
                appearance: textfield;
              }
            }
            .total {
              color: #2e3832;
              font-family: Jost;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 36px; /* 180% */
              @media (max-width: 768px), (max-width: 1024px) {
                font-size: 10px;
              }
            }
          }
        }
        .actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 3% 0;
          @media (max-width: 768px), (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
          }
          .coupon {
            button {
              border: none;
              cursor: pointer;
              background: #137638;
              border-radius: 30px;
              padding: 10px;
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              padding: 5px 10px;
              color: #ffffff;
              text-align: center;
              font: 600 13px/36px "Jost", sans-serif;
              &:hover {
                background: var(--text-light-green-caption);
              }
            }
          }
          .promo-code {
            background: #ffffff;
            border-radius: 30px;
            border-style: solid;
            border-color: #e4e4e4;
            border-width: 1px;
            padding: 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            height: 49px;
            position: relative;
            color: #b0d1bd;
            text-align: center;
            font: 600 13px/36px "Jost", sans-serif;
          }
          .update {
            button {
              border: none;
              background: #137638;
              border-radius: 30px;
              padding: 10px;
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              padding: 5px 10px;
              position: relative;
              color: #ffffff;
              text-align: center;
              font: 600 13px/36px "Jost", sans-serif;
              cursor: pointer;
              &:hover {
                background: var(--text-light-green-caption);
              }
            }
            @media (max-width: 1024px) {
            }
            @media (max-width: 768px) {
            }
            @media (max-width: 426px) {
            }
          }
        }
      }
      .totaux {
        padding: 0 1rem;
        width: 25%;
        height: 395px;
        flex-shrink: 0;
        border-radius: 14px;
        border: 1px solid #e9e9e9;
        background: #fff;
        @media (max-width: 768px), (max-width: 1024px) {
          width: 30%;
        }
        @media (max-width: 426px) {
          margin: 10% 0;
          width: 100%;
        }

        .title {
          color: #000000;
          margin-top: 21px;
          text-align: left;
          font: 400 20px "Jost", sans-serif;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media (max-width: 768px), (max-width: 1024px) {
            font-size: 16px;
          }
        }
        .total {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          .total-title {
            color: #96c2a7;
            text-align: left;
            font: 400 18px "Jost", sans-serif;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
          .total-price {
            color: #000000;
            text-align: left;
            font: 700 18px "Jost", sans-serif;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 768px), (max-width: 1024px) {
              font-size: 16px;
            }
          }
        }
        .livraison-container {
          margin: 3% 0;
          display: flex;
          flex-direction: column;
          .livraison {
            display: flex;
            gap: 10px;
            .livraison-input:checked {
              background-color: var(--olive-green);
            }
            .livraison-label {
              color: #2e3832;
              text-align: left;
              font: 400 16px "Jost", sans-serif;
              @media (max-width: 768px), (max-width: 1024px) {
                font-size: 10px;
              }
            }
          }
        }
        .description {
          color: #2e3832;
          text-align: left;
          font: 400 16px "Jost", sans-serif;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media (max-width: 768px), (max-width: 1024px) {
            font-size: 13px;
          }
        }
        .caisse {
          background: #137638;
          border-radius: 30px;
          padding: 5px 1px;
          display: flex;
          gap: 10px;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 40px 5%;
          color: #ffffff;
          text-align: center;
          font: 600 13px/36px "Jost", sans-serif;
          cursor: pointer;
          @media (max-width: 768px), (max-width: 1024px) {
            font-size: 10px;
            height: 35px;
          }
        }
        .achats {
          color: #2e3832;
          font: 400 20px/36px "Jost", sans-serif;
          text-decoration: underline;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @media (max-width: 768px), (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .shoping-products {
    margin: 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .products-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;
      font: 400 20px "Jost", sans-serif;
      text-transform: uppercase;
      .actions {
        img {
          width: 80px;
          height: 80px;
          margin: 0 -25px;
          cursor: pointer;
        }
      }
    }
    .products-grids {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      place-items: center;
      .cart_item {
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        border: 1px solid #ccc;
        padding: 20px;
        width: 95%;
        height: 369px;
        background-color: white;
        img {
          height: 130px;
          width: 165px;
          max-width: 200px;
          margin: auto;
          background-color: white;
        }
        .cart_item_description {
          margin: 30px 0 0 0;
          background-color: #fff;
          .numero {
            text-align: left;
            font-weight: bold;
            background-color: #fff;
            color: var(--text-light-green-caption);
            font-weight: 400;
            font-size: 13px;
            line-height: 14.45px;
          }

          .name {
            text-align: left;
            margin-top: 5px;
            font-size: 1.2em;
            background-color: #fff;
            font-weight: 700;
            font-family: Jost;
            font-size: 16px;
            line-height: 18.79px;
          }
          .bottom {
            background-color: white;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .price {
              text-align: left;
              font-weight: 700;
              line-height: 20.23px;
              font-size: 16px;
              margin-top: 10px;
              background-color: #fff;
            }

            .add-to-cart {
              font-size: 10px;
              font-weight: 500;
              line-height: 17.34px;
              background-color: var(--olive-green);
              color: #fff;
              border: none;
              padding: 5px 10px;
              margin-top: 10px;
              cursor: pointer;
              border-radius: 5px;
              transition: background-color 0.3s;
            }

            .add-to-cart:hover {
              background-color: var(--text-light-green-caption);
            }
          }
        }
      }
    }
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
