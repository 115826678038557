.footer {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.875rem 6.25rem 0.875rem; // 20px 14px 100px 14px
  gap: 1.875rem; // 30px
  &-left {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; // 20px
    img {
      width: 8.875rem; // 142px
      height: 8.875rem; // 142px
    }
    &-address {
      display: flex;
      flex-direction: column;
      gap: 0.625rem; // 10px
      p,
      a {
        color: var(--text-light-green);
        font-size: 1rem; // 16px
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem; // 26px
      }
    }
    > div {
      a {
        font-size: 0.875rem; // 14px
      }
    }
    &-social_media {
      display: flex;
      gap: 0.625rem; // 10px
      img {
        width: 1.3125rem; // 21px
        height: 1.3125rem; // 21px
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    gap: 2rem; // 32px
    &-contact {
      display: flex;
      flex-direction: column;
      gap: 1.25rem; // 20px
      flex-shrink: 0;
      h3 {
        color: var(--olive-green);
        font-family: var(--main-font);
        font-size: 1.125rem; // 18px
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem; // 26px
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 0.625rem; // 10px
      }

      &-phone_horaire {
        display: flex;
        align-items: flex-start;
        gap: 0.625rem; // 10px
        img {
          margin-top: 0.5rem; // 8px
          width: 1.5rem; // 24px
          height: 1.5rem; // 24px
        }
        p {
          color: var(--text-light-green);
          font-size: 0.875rem; // 14px
          font-style: normal;
          font-weight: 400;
          line-height: 1.625rem; // 26px
        }
        h4 {
          color: var(--olive-green);
          font-size: 1.5rem; // 24px
          font-style: normal;
          font-weight: 600;
          line-height: 2.25rem; // 36px
        }
        hr {
          height: 0.125rem; // 2px
          background-color: var(--olive-green);
          border: none;
        }
      }

      &-email {
        display: flex;
        align-items: center;
        gap: 0.625rem; // 10px
        color: var(--olive-green);
        font-size: 1rem; // 16px
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem; // 26px
      }
    }

    &-util_links {
      display: flex;
      width: 100%;
      justify-content: space-between;
      h3,
      a {
        color: var(--olive-green);
        font-size: 1.125rem; // 18px
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem; // 26px
      }
      a {
        font-size: 1rem; // 16px
        font-weight: 400;
        line-height: 1.25rem; // 20px
      }
      > div {
        display: flex;
        flex-direction: column;
        gap: 1.25rem; // 20px
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 0.75rem; // 12px
      }
    }
  }
}

@media (min-width: 26.5rem) {
  // 424px in rem units
  .footer {
    padding: 1.875rem 8% 6.25rem 8%; // 30px 8% 100px 8%
  }
}

@media screen and (min-width: 51rem) {
  // 824px in rem units
  .footer {
    flex-direction: row;
    align-items: flex-end;
    padding: 2.5rem 5% 6.25rem 5%; // 40px 5% 100px 5%
    &-left {
      padding-right: 3.75rem; // 60px
      border-right: 0.0625rem solid var(--olive-green); // 1px solid var(--olive-green)
    }
    &-right {
      flex: auto;
      flex-direction: row;
      > div {
        flex: 1;
      }
      &-util_links {
        width: auto;
      }
    }
  }
}

@media screen and (min-width: 90rem) {
  // 1440px in rem units
  .footer {
    padding: 3.125rem 10% 3.125rem 10%; // 50px 10% 50px 10%
    &-left {
      padding-right: 5.625rem; // 90px
      border-right: 0.0625rem solid var(--olive-green); // 1px solid var(--olive-green)
    }
  }
}
