:root {
  --olive-green: #137638;
  --olive-green-hover: #0f5c2f;
  --light-green-swiper-bg: #e5eecb;
  --text-light-green: #529434;
  --text-light-green-caption: #12a05c;
  --text-disabled-light-green: #b0d1bd;
  --light-green-bg: #f7f9ef;
  --light-green-sticker-bg: #92c020;
  --main-font: Jost;
  --liver: #4f4f4f;
  --avocado: #88b825;
  --loading-grey: #ededed;
  /* REACT PAGINATION  */
  --swiper-pagination-color: #137638;
  --swiper-pagination-bullet-size: 1rem;
  --swiper-pagination-bullet-width: 1rem;
  --swiper-pagination-bullet-height: 1rem;
  --swiper-pagination-bullet-inactive-color: #bbdeb4;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
